import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Select,
  Text,
} from '@chakra-ui/react'
import exportFromJSON from 'export-from-json'
import React, { useState } from 'react'
import {
  GA_CATEGORY_DIALOG_BOX,
  sectionDetailedTrack,
} from '../../containers/repository/repo.utils'
import { useLanguageContext } from '../../context/locale.context'
import { useLoadChildOrderActivity } from '../../hooks/activity.hooks'
import { getCurrentDateUTC } from '../../utils/common.util'

const ExportChildOrders = ({ isOpen, onClose, activity }) => {
  const [isError, setisError] = useState(false)
  const [orderFlag, setOrderFlag] = useState(3)
  const { mutate, isLoading } = useLoadChildOrderActivity()
  const {
    state: { locale },
  } = useLanguageContext()

  const exportToExcel = data => {
    exportFromJSON({
      data,
      fileName: `${activity.activity_title.split(' ').join('-')}`,
      exportType: 'xls',
    })
    onClose()
  }

  const downloadChildOrders = () => {
    mutate(
      {
        parent_activity_id: activity.activity_id,
        page_start: 0,
        page_limit: 500,
        startFrom: 0,
        sort_flag: 0,
        flag: Number(orderFlag),
        datetime_end: getCurrentDateUTC(),
        datetime_start: '1970-01-01 00:00:00',
      },
      {
        onSuccess: async data => {
          const { list } = data
          const exportData = list.map(i => {
            const {
              activity_title,
              activity_description,
              activity_cuid_1,
              activity_cuid_2,
              activity_cuid_3,
              activity_type_name,
              activity_datetime_start_expected,
              activity_datetime_end_deferred,
              activity_datetime_closed,
              activity_status_name,
              activity_status_type_name,
              activity_lead_asset_first_name,
              parent_activity_title,
              parent_activity_type_name,
              activity_creator_asset_first_name,
              activity_creator_operating_asset_first_name,
              activity_workflow_completion_percentage,
            } = i

            return {
              activity_title,
              activity_description,
              activity_cuid_1,
              activity_cuid_2,
              activity_cuid_3,
              activity_type_name,
              activity_datetime_start_expected,
              activity_datetime_end_deferred,
              activity_datetime_closed,
              activity_status_name,
              activity_status_type_name,
              activity_lead_asset_first_name,
              parent_activity_title,
              parent_activity_type_name,
              activity_creator_asset_first_name,
              activity_creator_operating_asset_first_name,
              activity_workflow_completion_percentage,
            }
          })
          exportToExcel(exportData)
        },
        onError: async error => {
          setisError(true)
        },
      }
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent borderRadius="sm">
        <ModalHeader fontSize="lg" fontWeight="400">
          {locale['Download Child Orders']}
        </ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody>
          {!isLoading && (
            <FormControl id="selectType">
              <FormLabel>{locale['Select type of child orders']}</FormLabel>
              <Select
                size="sm"
                placeholder={locale['Select order type']}
                value={orderFlag}
                onChange={e => {
                  setOrderFlag(e.target.value)
                }}
              >
                <option value={1}>{locale['Open child orders']}</option>
                <option value={3}>{locale['All child orders']}</option>
              </Select>
            </FormControl>
          )}
          {isLoading && (
            <Progress colorScheme={localStorage.getItem('color')} my={5} size="md" isIndeterminate />
          )}
          {isError && (
            <Text color="red">
              {locale['Something went wrong please try again!']}
            </Text>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            borderRadius="sm"
            size="sm"
            variant="outline"
            colorScheme={localStorage.getItem('color')}
            mr={3}
            onClick={e => {
              sectionDetailedTrack({
                category: GA_CATEGORY_DIALOG_BOX,
                action: 'Export Child Orders',
                label: 'Close Clicked',
              })
              onClose(e)
            }}
          >
            {locale['Close']}
          </Button>
          {!isError && (
            <Button
              borderRadius="sm"
              size="sm"
              variant="solid"
              colorScheme={localStorage.getItem('color')}
              bg={localStorage.getItem('color')}
              isLoading={isLoading}
              onClick={e => {
                sectionDetailedTrack({
                  category: GA_CATEGORY_DIALOG_BOX,
                  action: 'Export Child Orders',
                  label: 'Download Selected',
                })
                downloadChildOrders(e)
              }}
            >
              {locale['Download']}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
export default ExportChildOrders
