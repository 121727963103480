import React, { useState } from 'react'
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react'
import {
  GA_CATEGORY_DIALOG_BOX,
  sectionDetailedTrack,
} from '../../containers/repository/repo.utils'
import { useLanguageContext } from '../../context/locale.context'
const DeleteDialog = ({ filename, coinfirmDelete, isOpenDialog }) => {
  const [isOpen, setIsOpen] = useState(isOpenDialog)
  const cancelRef = React.useRef()
  const {
    state: { locale },
  } = useLanguageContext()
  useState(() => {
    setIsOpen(isOpenDialog)
  }, [isOpenDialog])

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={() => setIsOpen(false)}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {locale['Delete']} {filename}
          </AlertDialogHeader>

          <AlertDialogBody>
            {locale["Are you sure? You can't undo this action afterwards."]}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                sectionDetailedTrack({
                  category: GA_CATEGORY_DIALOG_BOX,
                  action: 'Alert Dialog Footer',
                  label: 'Cancel Clicked',
                })
                setIsOpen(false)
              }}
            >
              {locale['Cancel']}
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                sectionDetailedTrack({
                  category: GA_CATEGORY_DIALOG_BOX,
                  action: 'Alert Dialog Footer',
                  label: 'Delete Button Clicked',
                })
                coinfirmDelete()
                setIsOpen(false)
              }}
              ml={3}
            >
              {locale['Delete']}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
export default DeleteDialog
