import React, { useRef, useState, useEffect } from 'react'
import {
	Box,
	Button,
	Center,
	Divider,
	Flex,
	Heading,
	HStack,
	Icon,
	IconButton,
	List,
	ListItem,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Spinner,
	Text,
	VStack,
} from '@chakra-ui/react'
import { useDocRepoPreviewList } from '../../hooks/documents.hooks'
import { timelineDateFormat } from '../../utils/date.utils'
import moment from 'moment'

const FilePreviewModal = ({ doc, isOpen, onClose }) => {
	const inputRef = useRef()
	const [fileList, setfileList] = useState([])

	const { mutate: docList } = useDocRepoPreviewList()

	useEffect(() => {
		if (doc.document_repository_id) {
			docList(
				{
					document_repository_id: doc.document_repository_id,
					start_from: 0,
					limit_value: 20,
					asset_id: 0,
				},
				{
					onSuccess: async res => {
						const {
							data: { response = [] },
						} = res
						setfileList(response)
					},
					onError: async err => {
						setfileList([])
					},
				}
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [doc])

	return (
		<Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent borderRadius='md' bg='white' minW='40vw' h='300px'>
				<ModalHeader fontSize='lg' fontWeight='400'>
					{'File Preview History'}
				</ModalHeader>
				<ModalCloseButton />
				<Divider />
				<ModalBody p={0} w='100%'>
					<HStack h='full'>
						<VStack
							h='100%'
							borderRightRadius='md'
							flex={1}
							bg='white'
							display='flex'
						>
							<VStack w='100%' mx='auto' flex={1} mt={1} alignItems='center'>
								<List
									w='full'
									spacing={3}
									h='300px'
									overflowX={'hidden'}
									overflowY='auto'
								>
									{fileList.length > 0 ? (
										fileList.map((file, index) => {
											return (
												<>
													<ListItem>
														<Flex ml={5} spacing={2}>
															<Box ml={2} flex={1}>
																<Text fontSize='16px' fontWeight={'800'}>
																	User: {file?.operating_asset_first_name}
																</Text>
																<Text fontSize='14px'>
																	Date:{' '}
																	{!!file?.log_datetime
																		? timelineDateFormat(file?.log_datetime)
																		: ''}
																</Text>
																<Text fontSize='14px'>
																	Duration:{' '}
																	{!!file?.preview_duration
																		? moment
																				.utc(file?.preview_duration * 1000)
																				.format('mm:ss')
																		: ''}
																</Text>
															</Box>
														</Flex>
													</ListItem>
													<Divider border='1px' borderColor='secondary' />
												</>
											)
										})
									) : (
										<>
											<ListItem>
												<Flex m={5} spacing={2}>
													<Box ml={2} flex={1}>
														<Text
															color={'brand.800'}
															fontSize='16px'
															fontWeight={'800'}
														>
															Preview history data not available
														</Text>
													</Box>
												</Flex>
											</ListItem>
											<Divider border='1px' borderColor='secondary' />
										</>
									)}
								</List>
							</VStack>
							<ModalFooter
								w='full'
								py={2}
								alignSelf='flex-end'
								justifySelf='flex-end'
							>
								<Button
									variant='outline'
									colorScheme={localStorage.getItem('color')}
									onClick={e => {
										onClose(e)
									}}
									borderRadius='md'
									boxShadow='md'
									size='md'
								>
									{'Close'}
								</Button>
							</ModalFooter>
						</VStack>
					</HStack>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default FilePreviewModal