import React, { useState } from 'react'
import {
  Button,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Progress,
  Text,
} from '@chakra-ui/react'
import Select from '../chakra-react-select'
import {
  GA_CATEGORY_DIALOG_BOX,
  sectionDetailedTrack,
} from '../../containers/repository/repo.utils'
import { useLanguageContext } from '../../context/locale.context'

const ExportFormsDialog = ({
  isOpen,
  onClose,
  exportForms,
  isLoading,
  formsList,
  setSelectedForms,
  selectedForms,
}) => {
  const {
    state: { locale },
  } = useLanguageContext()
  const options = formsList.map((f, i) => {
    f['form_sequence_id'] = i + 1
    return {
      label: f.form_name,
      value: f.form_id,
      data: f,
    }
  })
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent borderRadius="sm">
        <ModalHeader fontSize="lg" fontWeight="400">
          {locale['Export Forms Data to PDF']}
        </ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody>
          <Text fontSize="md">{locale['Select Forms']}</Text>
          <Select
            isMulti={true}
            isDisabled={false}
            id={'export-forms'}
            options={options}
            onChange={setSelectedForms}
            placeholder={locale['Select Forms']}
            borderRadius="md"
            value={selectedForms}
            size="sm"
          />
          {isLoading && <Progress my={5} size="xs" isIndeterminate />}
        </ModalBody>
        <ModalFooter>
          <Button
            borderRadius="sm"
            size="sm"
            variant="outline"
            colorScheme={localStorage.getItem('color')}
            mr={3}
            onClick={e => {
              sectionDetailedTrack({
                category: GA_CATEGORY_DIALOG_BOX,
                action: 'Export Forms Dialog',
                label: 'Close Selected',
              })
              onClose(e)
            }}
          >
            {locale['Close']}
          </Button>
          <Button
            borderRadius="sm"
            size="sm"
            variant="solid"
            colorScheme={localStorage.getItem('color')}
            bg={localStorage.getItem('color')}
            isLoading={isLoading}
            onClick={e => {
              sectionDetailedTrack({
                category: GA_CATEGORY_DIALOG_BOX,
                action: 'Export Forms Dialog',
                label: 'Export Selected',
              })
              exportForms(e)
            }}
          >
            {locale['Export']}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
export default ExportFormsDialog
