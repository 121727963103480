import React from 'react'
import ReactDatePicker from 'react-datepicker'
import './style.scss'
// import 'react-datepicker/dist/react-datepicker.css';

const DatePicker = ({
	selectedDate,
	onChange,
	isClearable = false,
	showPopperArrow = true,
	customInput,
	disableData,
	...props
}) => {
	const filterTime = time => {
		if (!selectedDate) {
			return true // Disable all times when no date is selected
		}

		const currentDate = new Date()
		const selectedDateTime = new Date(
			selectedDate.getFullYear(),
			selectedDate.getMonth(),
			selectedDate.getDate(),
			time.getHours(),
			time.getMinutes(),
			time.getSeconds()
		)

		return selectedDateTime > currentDate
	}

	const disabledDatesWithTimes = disableData?.map(slot => {
		const startDate = new Date(slot.start_date_time)
		const endDate = new Date(slot.end_date_time)

		return {
			date: startDate,
			startTime: startDate,
			endTime: endDate,
		}
	})

	const generateDisabledTimes = date => {
		const disabledTimeObject = disabledDatesWithTimes?.find(
			disabledDate => disabledDate.date.toDateString() === date.toDateString()
		)

		if (disabledTimeObject) {
			const { startTime, endTime } = disabledTimeObject
			const disabledTimes = []

			let currentTime = new Date(startTime)
			while (currentTime <= endTime) {
				disabledTimes.push(new Date(currentTime))
				currentTime.setMinutes(currentTime.getMinutes() + 15)
			}

			return disabledTimes
		}

		return []
	}
	return (
		<ReactDatePicker
			showYearDropdown
			selected={selectedDate}
			onChange={onChange}
			isClearable={isClearable}
			showPopperArrow={showPopperArrow}
			customInput={customInput}
			excludeTimes={generateDisabledTimes(selectedDate)}
			filterTime={disableData?.length > 0 ? filterTime : ''}
			// popperPlacement="top-start"
			{...props}
		/>
	)
}

export default DatePicker
