/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	Button,
	Center,
	Flex,
	HStack,
	Icon,
	Spinner,
	Text,
	Tooltip,
	VStack,
} from '@chakra-ui/react'
import React, { useRef, useState, useEffect } from 'react'
import { FiUploadCloud, FiDownloadCloud, FiTrash } from 'react-icons/fi'
import { BsFileEarmark } from 'react-icons/bs'
import { pushFileToS3, downloadAwsFile } from '../../utils/aws.util'
import { useDropzone } from 'react-dropzone'
import {
	GA_CATEGORY_CUSTOM_INPUT,
	sectionDetailedTrack,
} from '../../containers/repository/repo.utils'
import { useLanguageContext } from '../../context/locale.context'
import { useLoadBucketName } from '../../hooks/shared.hooks'
import { useSmartFormContext } from '../../context/smart-form.context'
import { smartFormConst } from '../../utils/action.constant'

const FileInput = ({
	placeholder,
	supportedFiles,
	onChange,
	onError = d => {},
	isDisabled,
	field,
	onExcelValidation,
	value,
	isFileUpload,
	minsize = 0,
	maxsize = 52428800,
	onValidation = d => {},
	onDelete = d => {},
	onReset = () => {},
	isMultiple = false,
}) => {
	const inputRef = useRef()
	const [isError, setisError] = useState(false)
	const [selectedFile, setselectedFile] = useState(false)
	const [errorMsg, setErrorMsg] = useState('')
	const [overlayOpacity, setoverlayOpacity] = useState(0)
	const [isUpload, setIsUpload] = useState(false)
	const { mutate, isLoading } = useLoadBucketName()
	const {
		state: { locale },
	} = useLanguageContext()
	const {
		state: { fields },
		dispatch: dispatchFormFields,
	} = useSmartFormContext()
	useEffect(() => {
		if (!!value && typeof value === 'string') {
			setselectedFile([
				{
					value,
					name: value.split('/').reverse()[0],
				},
			])
		}
	}, [value])

	useEffect(() => {
		if (!!isError) {
			onError(
				'File format not supported or File size exceeds limit or File name should not contain multiple (dots)'
			)
		} else {
			// onError('')
		}
	}, [isError])

	const deleteFile = () => {
		setselectedFile(false)
		onDelete(true)
		dispatchFormFields({
			type: smartFormConst.SET_DELETE_ID,
			field: field,
		})
	}
	const onHandleFileChange = async acceptedFiles => {
		let error = false
		let msg = ''
		isFileUpload(true)
		setIsUpload(true)
		const files = [...acceptedFiles].filter(file => {
			if (file.name.split('.').length > 2) {
				return true
			}
			return true
		})
		if (files.length === 0) {
			error = true
			msg = locale['Selected File is Not supported']
			if (!!error) {
				setisError(error)
				setErrorMsg(msg)
				setIsUpload(false)
				isFileUpload(false)
				return
			}
		} else {
			if (!!field && !!field.field_validation_enabled && !!onExcelValidation) {
				onExcelValidation(files)
				setIsUpload(false)
			} else {
				const pFiles = files.map(async file => {
					if (supportedFiles && !supportedFiles.includes(file.type)) {
						error = true
						msg = locale['Selected File is Not supported']
						return false
					} else if (file.size > maxsize) {
						error = true
						msg = locale['Selected File size exceeds limit']
						return false
					} else {
						error = false
						msg = ''
					}
					if (!!error) {
						return false
					}
					const blob = file.slice(0, file.size, file.type)
					const newFile = new File([blob], file.name.replace(/ /gi, '-'), {
						type: blob.type,
					})
					let result = ''
					if (
						field?.field_inline_data &&
						field.field_inline_data.flag_public_access === 1
					) {
						let getBucketData = new Promise((resolve, reject) => {
							mutate(
								{ device_os_id: 5 },
								{
									onSuccess: async data => {
										resolve(data)
									},
								}
							)
						})
						await getBucketData.then(async res => {
							if (res && res.length > 0) console.log(res, 'res data  ')
							result = await pushFileToS3(newFile, '', res[0].bucket_name)
						})
					} else {
						result = await pushFileToS3(newFile)
					}
					isFileUpload(false)
					setIsUpload(false)
					return result
					// const { url } = await new Promise(resolve => resolve(result))
					// onChange({ url: url, isValid: !error, msg: msg })
				})
				if (!!error) {
					setisError(error)
					setErrorMsg(msg)
					setIsUpload(false)
					isFileUpload(false)
					return
				}
				dispatchFormFields({
					type: smartFormConst.SET_DELETE_ID,
					field: '',
				})
				onDelete(false)
				setisError(false)
				setselectedFile(files)
				setErrorMsg(msg)

				const urls = await Promise.all(pFiles)
				let url = isMultiple ? urls : urls[0].url

				onChange({ url: url, isValid: !error, msg: msg })
			}
		}
	}

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop: onHandleFileChange,
		disabled: isDisabled,
		accept: supportedFiles,
	})

	const supportedFileName = () => {
		let result = ''
		if (placeholder) {
			if (placeholder.includes('application/pdf')) {
				result = `${result}, pdf `
			}
			if (
				placeholder.includes('vnd.ms-excel') ||
				placeholder.includes('vnd.openxmlformats')
			) {
				result = `${result}, xls, xlsx, xlsb`
			}
			if (
				placeholder.includes('vnd.msword') ||
				placeholder.includes('vnd.openxmlformats')
			) {
				result = `${result}, doc, docs, docx`
			}
			if (placeholder.includes('image') || placeholder.includes('video')) {
				result = `${result}, mp4, jpg, png, jpeg`
			}
			if (
				!placeholder.includes('image') &&
				!placeholder.includes('video') &&
				!placeholder.includes('vnd.msword') &&
				!placeholder.includes('vnd.openxmlformats') &&
				!placeholder.includes('vnd.ms-excel') &&
				!placeholder.includes('vnd.openxmlformats') &&
				!placeholder.includes('application/pdf')
			) {
				result = placeholder
			}
		}
		return result
	}

	return (
		<Flex
			className={`file-input-${field.field_id}`}
			minH='120px'
			border='2px'
			borderColor='brand.800'
			borderStyle='dashed'
			borderRadius='10px'
			py={2}
			px={5}
			mx='auto'
			cursor='pointer'
			{...getRootProps()}
		>
			{isUpload ? (
				<Center w='100%' h='100px'>
					<Spinner />
				</Center>
			) : (
				<HStack
					justifyContent='space-around'
					w='100%'
					alignItems='center'
					spacing={3}
					mx={5}
				>
					{selectedFile && selectedFile.length > 0 ? (
						<Tooltip
							label={`${selectedFile[0].name}`}
							fontSize='sm'
							bg='secondary'
							color='black.700'
						>
							<Flex
								justifyContent='center'
								align='center'
								flexDirection='column'
								h='90px'
								w='90px'
								bg='white'
								borderRadius='lg'
								position='relative'
								onMouseOver={() => {
									setoverlayOpacity(0.8)
								}}
								onMouseLeave={() => {
									setoverlayOpacity(0)
								}}
							>
								{/* <Icon
                boxShadow="sm"
                position="absolute"
                cursor="pointer"
                top="0px"
                right="0px"
                _hover={{
                  bg: 'secondary',
                }}
                as={MdClose}
                w={4}
                h={4}
                color={localStorage.getItem('color')}
                onClick={() => {
                  console.log('remove file from data');
                }}
              /> */}
								<Center position='relative'>
									<Box position='absolute' left='0' px={2} bg='white'>
										<Text fontSize='12px' textTransform='uppercase'>
											{selectedFile[0].name.split('.').reverse()[0]}
										</Text>
									</Box>
									<Icon
										as={BsFileEarmark}
										color='brand.900'
										h='4rem'
										w='4rem'
									/>
								</Center>
								<Text
									textAlign='center'
									fontSize='sm'
									overflow='hidden'
									textOverflow='ellipsis'
									whiteSpace='nowrap'
									width='100%'
								>{`${selectedFile[0].name}`}</Text>

								<Box
									position='absolute'
									top='0'
									bottom='0'
									left='0'
									right='0'
									width='full'
									height='full'
									opacity={overlayOpacity}
									transition='.3s ease'
									bg={localStorage.getItem('color')}
									display='flex'
									borderRadius='lg'
									px={2}
									py={2}
									alignItems='center'
									justifyContent={isDisabled ? 'center' : 'space-around'}
									zIndex='9'
									onClick={e => {
										sectionDetailedTrack({
											category: GA_CATEGORY_CUSTOM_INPUT,
											action: 'File Input',
											label: 'Download AWS File',
										})
										if (isDisabled && selectedFile[0].value) {
											downloadAwsFile(selectedFile[0].value)
										} else {
											e.stopPropagation()
										}
									}}
								>
									{isDisabled ? (
										<Icon
											// onClick={() =>
											//   selectedFile[0].value
											//     ? downloadAwsFile(selectedFile[0].value)
											//     : null
											// }
											as={FiDownloadCloud}
											color='brand.600'
											h={12}
											w={12}
										/>
									) : (
										<>
											<Icon
												onClick={e => {
													sectionDetailedTrack({
														category: GA_CATEGORY_CUSTOM_INPUT,
														action: 'File Input',
														label: 'Download AWS File',
													})
													if (selectedFile[0].value) {
														downloadAwsFile(selectedFile[0].value)
													}
													e.stopPropagation()
												}}
												as={FiDownloadCloud}
												color='brand.600'
												h={6}
												w={6}
											/>

											<Icon
												onClick={e => {
													sectionDetailedTrack({
														category: GA_CATEGORY_CUSTOM_INPUT,
														action: 'File Input',
														label: 'Delete File',
													})
													deleteFile()
													onReset()
													// TODO: not working for required fields
													e.stopPropagation()
												}}
												as={FiTrash}
												color='brand.600'
												h={6}
												w={6}
											/>
										</>
									)}
								</Box>
							</Flex>
						</Tooltip>
					) : (
						<VStack>
							<Icon
								as={FiUploadCloud}
								w={10}
								h={10}
								color='brand.800'
								strokeWidth='1px'
							/>
							<Text color='brand.800' fontSize='12px' whiteSpace='nowrap'>
								{locale['Drag and Drop Files']}
							</Text>
						</VStack>
					)}
					<Text color='brand.800' fontSize='18px' textAlign='center'>
						{locale['OR']}
					</Text>
					<VStack>
						<input
							type='file'
							name='file'
							// onChange={onHandleFileChange}
							ref={inputRef}
							style={{ display: 'none' }}
							accept={supportedFiles}
							{...(isMultiple ? { multiple: true } : {})}
							{...getInputProps()}
						></input>
						<Button
							isDisabled={isDisabled}
							borderRadius='md'
							boxShadow='md'
							size='sm'
							variant='solid'
							colorScheme={localStorage.getItem('color')}
							bg={localStorage.getItem('color')}
							fontSize='14px'
							onClick={() => {
								sectionDetailedTrack({
									category: GA_CATEGORY_CUSTOM_INPUT,
									action: 'File Input',
									label: 'Browse File',
								})
								!!inputRef.current && inputRef.current.click()
							}}
						>
							{locale['BROWSE FILE']}
						</Button>
						<Text color='brand.800' fontSize='10px' textAlign='center'>
							{locale['Supports']}:
							<Text
								display='inline'
								as='span'
								mx={2}
								color='brand.800'
								fontSize='10px'
								textAlign='center'
								textTransform='lowercase'
							>
								{supportedFileName()}
							</Text>
						</Text>
					</VStack>
					{/* {isError && (
            <Text color="red" fontSize="12px" textAlign="center" px={5}>
              {errorMsg}
            </Text>
          )} */}
				</HStack>
			)}
		</Flex>
	)
}

export default FileInput
