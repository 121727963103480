import React, { useState } from 'react'
import {
	Button,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
} from '@chakra-ui/react'
import { useLanguageContext } from '../../context/locale.context'

const NoPreviewAlert = ({ isOpen, onClose }) => {
	const cancelRef = React.useRef()
	const {
		state: { locale },
	} = useLanguageContext()

	return (
		<AlertDialog
			isOpen={isOpen}
			leastDestructiveRef={cancelRef}
			onClose={onClose}
		>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize='lg' fontWeight='bold'>
						{'No Preview'}
					</AlertDialogHeader>

					<AlertDialogBody>
						{'Preview not available for this file format'}
					</AlertDialogBody>

					<AlertDialogFooter>
						<Button ref={cancelRef} onClick={onClose} colorScheme={localStorage.getItem('color')}>
							{locale['Ok']}
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	)
}
export default NoPreviewAlert
