import React, { useState } from 'react'
import {
  Button,
  Divider,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Progress,
  Checkbox,
} from '@chakra-ui/react'
import {
  GA_CATEGORY_DIALOG_BOX,
  sectionDetailedTrack,
} from '../../containers/repository/repo.utils'
import { useLanguageContext } from '../../context/locale.context'
const ExportTimelineDialog = ({
  isOpen,
  onClose,
  exportTimeline,
  isLoading,
  isSysMessage,
  setSysMesssage,
}) => {
  const cancelRef = React.useRef()
  const {
    state: { locale },
  } = useLanguageContext()
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent borderRadius="sm">
          <AlertDialogHeader fontSize="lg" fontWeight="400">
            {locale['Export Timeline']}
          </AlertDialogHeader>
          <Divider />
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Checkbox
              defaultIsChecked={isSysMessage}
              onChange={e => setSysMesssage(e.target.checked)}
            >
              {locale['Show system messages']}
            </Checkbox>
            {isLoading && <Progress size="xs" isIndeterminate />}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              borderRadius="sm"
              size="sm"
              variant="outline"
              colorScheme={localStorage.getItem('color')}
              onClick={e => {
                sectionDetailedTrack({
                  category: GA_CATEGORY_DIALOG_BOX,
                  action: 'Export Timeline Dialog',
                  label: 'Close Selected',
                })
                onClose(e)
              }}
            >
              {locale['Cancel']}
            </Button>
            <Button
              borderRadius="sm"
              size="sm"
              colorScheme={localStorage.getItem('color')}
              bg={localStorage.getItem('color')}
              variant="solid"
              onClick={() => {
                sectionDetailedTrack({
                  category: GA_CATEGORY_DIALOG_BOX,
                  action: 'Export Timeline Dialog',
                  label: 'Exporting File Timeline',
                })
                exportTimeline()
              }}
              ml={3}
              isLoading={isLoading}
            >
              {locale['Export']}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
export default ExportTimelineDialog
