/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import {
  Button,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  Center,
  useDisclosure,
} from '@chakra-ui/react'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import '../../containers/export-panel/export/export.scss'
import {
  useAlterFileCover,
  useLoadEDCFieldId,
  useAddTimelineUpdate,
} from '../../hooks/activity.hooks'

import {
  getCurrentDateUTC,
  formatDueDateForTimeline,
  formatNoteUpdateDate,
  formatDueDateForTimelineCurrDate,
} from '../../utils/date.utils'
import { useAuthContext } from '../../context/auth.context'
import { useLanguageContext } from '../../context/locale.context'
import {
  GA_CATEGORY_DIALOG_BOX,
  sectionDetailedTrack,
} from '../../containers/repository/repo.utils'

function DueDateEditModal({ isOpen, onClose, activity }) {
  const {
    state: { authData },
  } = useAuthContext()
  const {
    state: { locale },
  } = useLanguageContext()

  const {
    isOpen: isDateView,
    onOpen: openDateView,
    onClose: closeDateView,
  } = useDisclosure()

  const [activityDate, setActivityDate] = useState(moment().toDate())
  const [isUpdated, setIsUpdated] = useState(true)

  const { mutate, isLoading } = useLoadEDCFieldId()
  const { mutate: mutateFileEditSave, isLoading: isActivityUpdating } =
    useAlterFileCover()
  const { mutate: mutateUpdateAddTimeline } = useAddTimelineUpdate()
  useEffect(() => {
    if (!isOpen) {
      closeDateView()
      setActivityDate(moment().toDate())
      setIsUpdated(false)
    }
  }, [isOpen])

  const buildTimelineEntryParams = data => {
    let newDueDate = JSON.stringify(formatDueDateForTimeline(data.duedate.new))
    let oldDueDate = JSON.stringify(
      formatDueDateForTimeline(activity.activity_datetime_end_deferred)
    )
    let attachmentsArr = []
    let activityTimelineJson = {}
    activityTimelineJson = {
      content:
        'Due date changed from ' +
        oldDueDate +
        ' to ' +
        newDueDate +
        ' by ' +
        authData.operating_asset_first_name,
      subject: 'Note - ' + formatNoteUpdateDate(),
      mail_body:
        'Due date changed from ' +
        oldDueDate +
        ' to ' +
        newDueDate +
        ' by ' +
        authData.operating_asset_first_name,
      attachments: attachmentsArr,
      activity_reference: [
        {
          activity_title: '',
          activity_id: '',
        },
      ],
      asset_reference: [{}],
      form_approval_field_reference: [],
    }

    const timelineParams = {
      activity_id: activity.activity_id,
      // auth_asset_id: asset_id,
      asset_message_counter: 0,
      flag_pin: 0,
      flag_priority: 0,
      flag_offline: 0,
      flag_retry: 0,
      // message_unique_id: getMessageUniqueId(asset_id),
      activity_channel_id: 0,
      activity_channel_category_id: 0,
      track_latitude: '0.0',
      track_longitude: '0.0',
      track_altitude: 0,
      track_gps_datetime: getCurrentDateUTC(),
      track_gps_accuracy: '0',
      track_gps_status: 0,
      activity_type_category_id: activity.activity_type_category_id,
      activity_type_id: activity.activity_type_id,
      activity_parent_id: activity.parent_activity_id,
      activity_sub_type_id: -1,
      activity_timeline_collection: JSON.stringify(activityTimelineJson),
      activity_stream_type_id: 734,
      timeline_stream_type_id: 734,
      activity_timeline_text: '',
      activity_timeline_url: '',
      // data_entity_inline: JSON.stringify(activityTimelineJson),
      operating_asset_first_name: authData.operating_asset_first_name,
      datetime_log: getCurrentDateUTC(),
      from_date: activity.activity_datetime_end_deferred,
      to_date: data.duedate.new,
      isDueDate: true,
    }
    return timelineParams
  }

  const updateDueDate = () => {
    mutate(
      { activity, activityDate },
      {
        onSuccess: async data => {
          const dueDate = data
          const activity_cover_data = JSON.stringify({
            title: {
              old: activity.activity_title,
              new: activity.activity_title,
            },
            description: { old: '', new: '' },
            duedate: data.duedate,
          })
          mutateFileEditSave(
            { activity, activity_cover_data },
            {
              onSuccess: async res => {
                const {
                  data: { status },
                } = res
                if (status === 200) {
                  let timelineParams = buildTimelineEntryParams(dueDate)
                  mutateUpdateAddTimeline(timelineParams, {
                    onSuccess: () => {
                      setIsUpdated(true)
                    },
                  })
                }
              },
            }
          )
        },
      }
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      isCentered={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Center>{locale['Change closure Date?']}</Center>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isUpdated ? (
            <Text mb={8} color="#000">
              <Center>
                {locale['Due date has been updated successfully!']}
              </Center>
            </Text>
          ) : isDateView ? (
            <Center>
              <Box w="200px">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  className="date-css"
                  selected={activityDate}
                  minDate={moment().toDate()}
                  onChange={e => setActivityDate(moment(e).toDate())}
                />
              </Box>
            </Center>
          ) : (
            <Text color="#000">
              <Center>
                {
                  locale[
                    'Looks like there is a delay on this file, you can update the closure date and inform all stake holders?'
                  ]
                }
              </Center>
            </Text>
          )}
        </ModalBody>
        <ModalFooter>
          {isUpdated ? null : isDateView ? (
            <Box>
              <Button
                isLoading={isLoading || isActivityUpdating}
                onClick={() => {
                  sectionDetailedTrack({
                    category: GA_CATEGORY_DIALOG_BOX,
                    action: 'Due Date Modal',
                    label: 'Update Due Date',
                  })
                  updateDueDate()
                }}
                colorScheme={localStorage.getItem('color')}
                bg={localStorage.getItem('color')}
                mr="4"
              >
                {locale['Submit']}
              </Button>
            </Box>
          ) : (
            <Box>
              <Button
                onClick={() => {
                  sectionDetailedTrack({
                    category: GA_CATEGORY_DIALOG_BOX,
                    action: 'Due Date Modal',
                    label: 'Closed Modal',
                  })
                  onClose(activity)
                }}
                colorScheme={localStorage.getItem('color')}
                variant="outline"
                mr="4"
              >
                {locale['Cancel']}
              </Button>
              <Button
                onClick={() => {
                  sectionDetailedTrack({
                    category: GA_CATEGORY_DIALOG_BOX,
                    action: 'Due Date Modal',
                    label: 'Open Date View',
                  })
                  openDateView()
                }}
                colorScheme={localStorage.getItem('color')}
                bg={localStorage.getItem('color')}
              >
                {locale['Continue']}
              </Button>
            </Box>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default DueDateEditModal
